import logo from "./logo.webp";
import blacklogo from "./blacklogo.webp";
import linkedin from "./linkedin.svg";
import homeabout from "./home_about-1-min.webp";
import marketfocus from "./market_focus-min-min.webp";
import client1 from "./client1.webp";
import client2 from "./client2.webp";
import client4 from "./client4.webp";
import client3 from "./client3.webp";
import client5 from "./client5.webp";
import team1 from "./Dusten.webp"
import team2 from "./Anna.webp"
import team3 from "./profile_img4.webp";
import partnet1 from "./t1.webp";
import partnet2 from "./t2.webp";
import partnet3 from "./t3.webp";
import timeline from "./timeline_1.svg";
import investorpagebg from "./investors_page_banner.webp";
import formbg from "./form_bg.webp";
import contactbg from "./contact_page_banner-1.webp";
import portfoliobg from "./portfolio_page_banner.webp";
import investor1 from "./investor1.webp";
import investor2 from "./investor2.webp";
import teamellipse1 from "./Ellipse-52-1.webp";
import teamellipse2 from "./Ellipse-53-1.webp";
import videocover from "./videocover.webp";
import aboutbanner from "./about_banner-min.webp";
import mediabg from "./video_page_banner.webp";
import market_focus from "./marketfocus.webp";
import roadmap from "./Brandon.webp";
import redwood1 from "./Unit.webp";
import redwood2 from "./Exterior-Night.webp";
import redwood3 from "./The-Falls.webp";
import soldstamp from "./sold-stamp.webp";
import podcast from "./podcast.webp";
import thumbnail1 from "./thumbnail1.webp";
import thumbnail2 from "./thumbnail2.webp";
import thumbnail3 from "./thumbnail3.webp";
import thumbnail4 from "./thumbnail4.webp";
import thumbnail5 from "./thumbnail5.webp";
import host1 from "./host1.webp";
import host2 from "./host2.webp";
import host3 from "./host3.webp";
import host4 from "./host6.webp";
import host5 from "./host7.webp";
import playbutton from "./play.webp";
import timeline1 from "./timeline_1.svg";
import timeline2 from "./timeline_2.svg";
import recentproject from "./recentproject.webp";
import recentproject1 from "./recentproject.webp";
import videoCover2 from "./video-cover.webp";
import currentimgbg from "./currentproject/currentprojectbg.webp";
import currentprojectimg1 from "./currentproject/projectimg1.webp";
import currentprojectimg2 from "./currentproject/projectimg2.webp";
import currentprojectimg3 from "./currentproject/projectimg3.webp";
import aspencover from "./currentproject/aspencover.webp";
import spotlightbg from "./currentproject/spotlightbg.webp";
import broadwalkteam1 from "./currentproject/boardwalk-team-1.webp";
import broadwalkteam2 from "./currentproject/boardwalk-team-2.webp";
import broadwalkteam3 from "./currentproject/boardwalk-team-3.webp";
import checksvg from "./check.svg";
import podcastbannerimg from "./podcastbanner.webp";
import nate from "./Nate.webp";
import levi from "./Levi.webp"
import blogHero from "./blog-page-hero.webp";

export const image = {
  podcastbannerimg,
  logo,
  blacklogo,  
  linkedin,
  homeabout,
  marketfocus,
  client1,
  client2,
  client3,
  client4,
  client5,
  team1,
  team2,
  team3,
  partnet1,
  partnet2,
  partnet3,
  timeline,
  investorpagebg,
  formbg,
  investor1,
  investor2,
  contactbg,
  teamellipse1,
  teamellipse2,
  portfoliobg,
  videocover,
  aboutbanner,
  mediabg,
  market_focus,
  roadmap,
  redwood1,
  redwood2,
  redwood3,
  soldstamp,
  podcast,
  thumbnail1,
  thumbnail2,
  thumbnail3,
  thumbnail4,
  thumbnail5,
  host1,
  host2,
  host3,
  host4,
  host5,
  playbutton,
  timeline1,
  timeline2,
  recentproject,
  videoCover2,
  currentimgbg,
  currentprojectimg1,
  currentprojectimg2,
  currentprojectimg3,
  aspencover,
  spotlightbg,
  broadwalkteam1,
  broadwalkteam2,
  broadwalkteam3,
  checksvg,
  recentproject1,
  nate,
  blogHero,
  levi,
};
