import "./App.css";
import Routes from "./Routes/Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, delay: 100 });
  }, []);

  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
