import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "../View/Layout/Loader";
import Layout from "../View/Layout/Layout";
import Home from "../View/Pages/Home/Home"; // Keep Home direct for fast LCP

// Lazy Load Components
const About = lazy(() => import("../View/Pages/About/index"));
const Media = lazy(() => import("../View/Pages/Media/index"));
const Invest = lazy(() => import("../View/Pages/Investor/index"));
const Podcast = lazy(() => import("../View/Pages/Podcast/index"));
const Portfolio = lazy(() => import("../View/Pages/Portfolio/index"));
const Contact = lazy(() => import("../View/Pages/Contact"));
const CurrentProject = lazy(() => import("../View/Pages/CurrentProject"));
const PrivacyPolicy = lazy(() => import("../View/Pages/PrivacyPolicy"));
const BlogPage = lazy(() => import("../View/Pages/BlogPage"));
const BlogDetailsPage = lazy(() => import("../View/Pages/BlogDetailsPage"));
const Errorpage = lazy(() => import("../View/Pages/Errorpage"));

const Routes = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        {
          path: "/about",
          element: (
            <Suspense fallback={Loader}>
              <About />
            </Suspense>
          ),
        },
        {
          path: "/media",
          element: (
            <Suspense fallback={Loader}>
              <Media />
            </Suspense>
          ),
        },
        {
          path: "/investor",
          element: (
            <Suspense fallback={Loader}>
              <Invest />
            </Suspense>
          ),
        },
        {
          path: "/podcast",
          element: (
            <Suspense fallback={Loader}>
              <Podcast />
            </Suspense>
          ),
        },
        {
          path: "/portfolio",
          element: (
            <Suspense fallback={Loader}>
              <Portfolio />
            </Suspense>
          ),
        },
        {
          path: "/contact",
          element: (
            <Suspense fallback={Loader}>
              <Contact />
            </Suspense>
          ),
        },
        {
          path: "/current-project",
          element: (
            <Suspense fallback={Loader}>
              <CurrentProject />
            </Suspense>
          ),
        },
        {
          path: "/privacy-policy",
          element: (
            <Suspense fallback={Loader}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "/blogs",
          element: (
            <Suspense fallback={Loader}>
              <BlogPage />
            </Suspense>
          ),
        },
        {
          path: "/blog/*",
          element: (
            <Suspense fallback={Loader}>
              <BlogDetailsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/404",
      element: (
        <Suspense fallback={Loader}>
          <Errorpage />
        </Suspense>
      ),
    },
    { path: "*", element: <Navigate to="/404" /> },
  ]);

  return element;
};

export default Routes;
