import React from 'react'

const Loader = () => {
  return (
    <div style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      loading...
    </div>
  )
}

export default Loader
