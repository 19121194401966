import React, { Suspense, lazy, useEffect } from "react";
import "./style.scss";

import Loader from "../../Layout/Loader";
import HeroSection from "../../../Component/HeroSection/HeroSection";

const AboutSection = lazy(() =>
  import("../../../Component/AboutSection/AboutSection")
);
const ServiceSection = lazy(() =>
  import("../../../Component/ServiceSection/ServiceSection")
);
const Videomessage = lazy(() =>
  import("../../../Component/Videomessage/Videomessage")
);
const RecentProject = lazy(() =>
  import("../../../Component/RecentProjects/RecentProjects")
);
const Contact = lazy(() => import("../../../Component/contactsection/Contact"));
const OngoingProjectSection = lazy(() =>
  import("../../../Component/OngoingProjectSection/OngoingProjectSection")
);

const Home = () => {
  useEffect(() => {
    const metaTag = document.querySelector("meta[name='description']");
    document.title = "Multifamily Property Investments | Mailbox Money";

    if (metaTag) {
      document.head.appendChild(metaTag);
      metaTag.setAttribute(
        "content",
        "Looking for passive income? Mailbox Money RE offers exclusive multifamily property investments with attractive returns and tax benefits for accredited investors."
      );
    }

    let linkTag = document.querySelector("link[rel='canonical']");
    if (linkTag) {
      linkTag.setAttribute("href", "https://mailboxmoneyre.com");
    } else {
      linkTag = document.createElement("link");
      linkTag.rel = "canonical";
      linkTag.href = "https://mailboxmoneyre.com";
    }

    if (!document.head.contains(linkTag)) {
      document.head.appendChild(linkTag);
    }
  }, []);

  return (
    <>
      <HeroSection />
      <Suspense fallback={<Loader />}>
        <AboutSection />
        <OngoingProjectSection />
        <ServiceSection />
        <Videomessage />
        <RecentProject />
        <Contact />
        {/* <Testimonails/> */}
        <button className="currentprojectbtn">
          <a href="/portfolio"> Current Projects </a>
          <svg
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5376 5.81934H11.5376H1.5376Z" fill="black"></path>
            <path
              d="M1.5376 5.81934H11.5376"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M8.5376 1.81934L12.5376 5.81934L8.5376 9.81934"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
      </Suspense>
    </>
  );
};

export default Home;
